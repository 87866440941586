@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);

:root {
  --primary-white: #fff;
  --primary-light-grey: #f4f4f4;
  --primary-red: #ff0000;
  --primary-dark: rgb(52, 58, 64);
  --primary-dark-grey: #666666;
  --primary-red2: #ef4035;
  --primary-hover-red: #f33501;
  --primary-blue: blue;
  --primary-orange: #f9ab00;
  --primary-green: green;
  --primary-black: #000000;
  --primary-nav: rgba(0,0,0,0.90);
  --primary-twitter: #55acee;
  --primary-instagram: #e4405f;
  --primary-facebook: #3b5999;
  --primary-dribbble: #ea4c89;
  --primary-carolina: #4B9CD3;
  --primary-grey: #ccd1d9;
  --primary-transparent-black: rgba(0,0,0,0.7);
  --primary-transparent-blackd: rgba(0,0,0,0.9);
}
html {
  background-color: #000000;
}

body {
  font-family: "Roboto Condensed", sans-serif !important;
}
.MuiTypography-body1 {
  font-family: "Roboto Condensed", sans-serif !important;
}
.MuiIcon-colorAction {
  color: "#fff" !important;
} 
.logo{
width: 7.25rem;
height: 3.75rem;

}


 .header-wraper {
   position: relative;
   
  background: linear-gradient(270deg, #eeaeca, #4b9cd3, #e0e994);
  background-size: 600% 600%;
  animation: bg 40s ease infinite;
   background-position: center;
   height: 100vh;
 }
@keyframes bg { 
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
 
 .typed {
   font-size: 2rem;
 }

 .tsparticles-canvas-el {
   position: absolute;
   height: 100vh;
   width: 100vh;
   z-index: 1;
 }

 @media(max-width: 768px) {
.particles-canvas{
  display: none;
}
.main-info h1{
  font-size: 3.0rem !important;
}
.typed-text {
  font-size: 1.3rem !important;
}
.contacts .container{
  padding: 0 1rem;
}
.contacts p{
  padding: 0 0.4rem;
}
.contacts {
  overflow-x: hidden;
}
.line-name {
  position: relative;
  width:0;
  top: -0.75rem;
  height: 0.125rem;
  background: #f9ab00;
  background: var(--primary-orange);
  display: inline-block;
}
input:focus+.line-name, textarea:focus+.line-name {
  width:100%;
  transition: width 0.5s ease-in-out;
}
.card-body h1{
  font-size: 2rem !important;
}
.card-header h4{
  font-size: 1.3rem !important;
}
 }

.blur-up {
  -webkit-filter: blur(0.3125rem);
  filter: blur(0.3125rem);
  transition: filter 3000ms 500ms, -webkit-filter 300ms 500ms;
}
.blur-up.lazyloaded {
  -webkit-filter: blur(0);
  filter: blur(0);
}
 .profile-img {
   
   border: 0.0625rem dotted #f9ab00;
   
   border: 0.0625rem dotted var(--primary-orange);
   border-radius: 50%;
   padding: .3rem; 
   
 }
 .MuiFormHelperText-root {
   color: #f33501 !important;
   color: var(--primary-hover-red) !important;
 }

 @media(max-width: 576px) {
  
   .photo-wrap {
    margin-bottom: 15%;
   }
  
  .carousel p{
    font-size: 0.75rem;
  }
  .line-name {
    position: relative;
    width:0;
    top: -0.75rem;
    height: 0.125rem;
    background: #f9ab00;
    background: var(--primary-orange);
    display: inline-block;
  }
  input:focus+.line-name, textarea:focus+.line-name {
    width: 100%;
    transition: width 0.5s ease-in-out;
  }
 
  .carousel .thumbs-wrapper {
    display: none;
  }
  .display-4 {
    font-size: 2rem !important;
  }
  .lead {
    font-size: 0.85rem !important;
  }
  .modal-dialog {
  margin: 0 !important;
  }
  .sapcard {
    
    height: 17.625rem !important;
    
  }
  .modal-90w {
    width: 95% !important;
    justify-content: center;
    justify-self: center;
    
  }
  .privacymodal {
    width: 95% !important;
  }
  .pricing-header {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .modal-footer {
    display: flex;
    justify-content: space-around !important;
  }
  .modal-footer button{
    width: 8rem !important;
  }
  .card-body h1{
    font-size: 2rem !important;
  }
  .card-body li{
    font-size: 1rem !important;
  }
  
  .sapcol {
    align-items: center !important;
  }
  .dialogform {
    width: 96% !important;
  }
 .MuiTypography-body1 {
   font-size: 0.8rem !important;
 }

 }

 .photo-wrap {
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 10%;
 }
 .about-heading {
   text-align: center;
 }

 .services {
   text-align: center;
   padding-bottom: 2rem;
   background: #f4f4f4;
   background: var(--primary-light-grey);
 }

 
 .service-list {
   text-align: left;
 }

 .services .circle {
   position:relative;
   margin: 0.375rem auto;
   background: #f9ab00;
   background: var(--primary-orange);
   border-radius: 50%;
   width: 3.125rem;
   height: 3.125rem;
 }
 
 .social li {
  list-style: none;
 }
 .social {
   display:flex;
   justify-content: center;
   align-items: center;
   padding: 0;
 }
 .social .icon {
   color: #000000;
   color: var(--primary-black);
   
 }
 .social .icon:hover {
  transform: scale(1.2);
 }

 .services .icon {
   font-size: 1.4rem;
   color: #fff;
   color: var(--primary-white);
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
 }
 @media(min-width: 575px) {
  .services .card {
    min-height: 26.5rem;
  }
  .line-name {
    position: relative;
    width:0;
    top: -0.75rem;
    height: 0.225rem;
    background: #f9ab00;
    background: var(--primary-orange);
    display: inline-block;
  }
  input:focus+.line-name, textarea:focus+.line-name {
    width:100%;
    transition: width 0.5s ease-in-out;
  }
  }
 
.services .card, .services .card .accord {
transition: 0.3s ease-in-out !important;
padding:0 !important;
}
 .services .card:hover {
   background: #f9ab00;
   background: var(--primary-orange);
   border-bottom: 0.3125rem solid rgb(52, 58, 64);
   border-bottom: 0.3125rem solid var(--primary-dark);
 }

 .services .card:hover .accord{
  background: #f9ab00;
  background: var(--primary-orange);
}

 .services .card:hover .icon {
   color: rgb(52, 58, 64);
   color: var(--primary-dark);
 }

 .services .card:hover .circle {
   background: #fff;
   background: var(--primary-white);
 }

 .services .card:hover p {
  color: #fff;
  color: var(--primary-white);
}
.services .card:hover ul {
  color: #fff;
  color: var(--primary-white);
}

.contacts {
  background: rgb(52, 58, 64);
  background: var(--primary-dark);
  padding: 3rem 0;
}

.contacts h1 {
  color: #f9ab00;
  color: var(--primary-orange);
  padding: 1.3rem 0;
}
.contacts p {
  color: #f4f4f4;
  color: var(--primary-light-grey)

}

.contacts .container {
  padding: 4rem 0;
}


.contacts input.form-control {
  margin: -0.3rem 0;

}

textarea.form-control {
  margin: -0.3rem 0;

}

.error-message {
  color: #fff;
  color: var(--primary-white);
  background: #f33501;
  background: var(--primary-hover-red);
  text-align: left;
  border-radius: 0.125rem;
  
}

.success-message {
 font-weight: 900;
 color: green;
 color: var(--primary-green);
}

.footer {
  background: #000000;
  background: var(--primary-black);
  color: #666666;
  color: var(--primary-dark-grey);
  padding: 3rem;
  
}

.footer a {
  text-decoration: none;
  color: #666666;
  color: var(--primary-dark-grey);
  cursor: pointer;
}
.footer .socialrow a:hover {
  text-decoration: none;
  
  transform: scale(1.2);
}
.footer .alinks a:hover, .footer .footlinks a:hover {
  text-decoration: none;
  color: #f9ab00;
  color: var(--primary-orange);
  
}
.footer .rights a:hover {
  text-decoration: none;
  
}
.footer .facebook:hover {
  
  color: #3b5999;
  
  color: var(--primary-facebook);
  
}
.footer .twitter:hover {
  
  color: #55acee;
  
  color: var(--primary-twitter);
  
}
.footer .instagram:hover {
  
  color: #e4405f;
  
  color: var(--primary-instagram);
  
}
.footer .dribbble:hover {
  
  color: #ea4c89;
  
  color: var(--primary-dribbble);
  
}
.footer p {
  margin:0;
}
.alinks {
  display:flex;
}
.rights {
  font-size:0.7rem;
}
.custom-icon {
  cursor: pointer;
  
}
.custom-icon:hover, .action:hover {
  transform: scale(1.2);
}
@media(max-width: 767px) {
  
  .footlinks {
    display: none;
  }
  .alinks {
    text-align: center;
    display: block !important;
    
  }
  .afoot{
    margin-bottom: 2rem;
    
  }
  .footer .logo {
    display: block;
  }
  
  }

  .socialrow{
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  }



.carousel .slide img {
  width: 9rem !important;
  object-fit: cover;
}

.carousel .slide {
  background: transparent !important;
  
  padding-top: 2rem;
}

.carousel-root {
  margin: auto !important;
  margin-top: 3% !important;
  
}



.carousel .thumb.selected {
  border-bottom: 0.1875rem solid #f9ab00 !important;
  border-bottom: 0.1875rem solid var(--primary-orange) !important;
  
}
.carousel .thumb {
  
  border: 0.1875rem solid transparent !important;
  padding: 0 !important;
  
  
}
.carousel .thumb:hover {
  border-bottom: 0.1875rem solid #ff0000 !important;
  border-bottom: 0.1875rem solid var(--primary-red) !important;
  transform: scale(1.2);
  
}
.tooltip-inner{
  background-color: rgba(0,0,0,0.7) !important;
  background-color: var(--primary-transparent-black) !important;
}

.bs-tooltip-left .arrow:before {
  border-left-color: rgba(0,0,0,0.7) !important;
  border-left-color: var(--primary-transparent-black) !important;
}

.bs-tooltip-bottom .arrow:before {
  border-bottom-color: rgba(0,0,0,0.7) !important;
  border-bottom-color: var(--primary-transparent-black) !important;
}
.bs-tooltip-right .arrow:before {
  border-right-color: rgba(0,0,0,0.7) !important;
  border-right-color: var(--primary-transparent-black) !important;
}

.modal-90w {
  min-width: 90%;
}


.card-body {
  padding: 0 !important;
  font-size: 1rem !important;
}

.services .card:hover .accord .summary {
  background: rgba(0, 0, 0, .16);
  
}
.services .card:hover .accord .summary li{
  color: #000000 !important;
  color: var(--primary-black) !important;
  
}

.summary li{
  list-style-type:square;
 margin-left: 1rem;
 
}

.summary{
  font-size: 2rem !important;
  background: rgba(0, 0, 0, .16);
}
.underline a:link, .underline a:visited, .underline a:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #fff;
  color: var(--primary-white);
}


.Toastify__toast--default   {
  background:#089b80 !important;
  color: #fff !important;
  
}
.Toastify__progress-bar--default {
  background: #666666 !important;
}
.Toastify__toast {
  font-weight: 700 !important;
  font-family: "Roboto Condensed", sans-serif !important;
  
}
.carousel.carousel-slider .control-arrow:hover {
background: transparent !important;
}

body.modal-open > :not(.modal) {
-webkit-filter: blur(10 px);
-moz-filter: blur(10px);
-o-filter: blur(10px);
-ms-filter: blur(10px);
filter: blur(10px);
z-index: -1;
transform: scale(1.1);
}

.close {
  text-shadow: none !important;
 
}

.MuiInputBase-multiline {
  padding: 0 0 !important;
}
.MuiOutlinedInput-inputMultiline {
  padding: 10px !important;
}

.formclose {
  color: rgba(255,255,255,0.9);
  font-size: 2rem;
}
.close:hover {
  transform: scale(1.2);
}

.feedimg {
  z-index: 2;
 position:relative;
}

.sapcard {
 height: 20.1875rem;
}





